import { SearchIcon } from "@heroicons/react/outline";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { loadPersistentState, savePersistentState } from "../../utils/helpers/misc";

interface SearchOption {
  value: string;
  name: string;
}

interface SearchFormV3Props {
  containerKey?: string;
  searchOptions: SearchOption[];
  disabled?: boolean;
  updateSearchQueryAndField: (searchQuery: string, searchField: string) => void;
  onLoadedStateChange?: (loaded: boolean) => void;
}

export type SearchFormV3Ref = {
  resetSearchForm: () => void;
};

const SearchFormV3 = forwardRef<SearchFormV3Ref, SearchFormV3Props>((props, ref) => {
  const [loadedState, setLoadedState] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>(props.searchOptions[0].value);

  useImperativeHandle(ref, () => ({
    resetSearchForm: () => {
      const defaultSearchQuery = "";
      const defaultSearchField = props.searchOptions[0].value;
      setSearchField(defaultSearchField);
      setSearchQuery(defaultSearchQuery);
      props.updateSearchQueryAndField(defaultSearchQuery, defaultSearchField);
    },
  }));

  useEffect(() => {
    if (!loadedState) {
      loadState();
      setLoadedState(true);
      props.onLoadedStateChange?.call(null, true);
    }
  });

  useEffect(() => {
    if (loadedState) persistState();
  }, [searchQuery, searchField, loadedState]);

  const loadState = () => {
    const [searchQuery, searchField] = loadPersistentState(
      props.containerKey || "",
      ["search-query", "search-field"],
      ["", props.searchOptions[0].value]
    );
    setSearchField(searchField);
    setSearchQuery(searchQuery);
    props.updateSearchQueryAndField(searchQuery, searchField);
  };

  const persistState = () => {
    savePersistentState(
      props.containerKey || "",
      ["search-query", "search-field"],
      [searchQuery, searchField]
    );
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.updateSearchQueryAndField(searchQuery, searchField);
      }}
      className="grid grid-cols-1 gap-y-1 gap-x-1 sm:grid-cols-6 items-center rounded-lg overflow-hidden mb-4 justify-between max-w-2xl"
    >
      <input
        className="col-span-1 sm:col-span-2 text-gray-900 text-sm bg-white flex-grow b-none px-3 border-gray-300 focus:ring-amber-500 focus:border-amber-500 rounded-lg"
        type="text"
        placeholder="Search (by field)"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        disabled={props.disabled}
      />
      <select
        value={searchField}
        onChange={(e) => setSearchField(e.target.value)}
        className="col-span-1 sm:col-span-2 text-sm text-gray-500 outline-none border-gray-300 px-4 pr-8 py-2 rounded-lg focus:ring-amber-500 focus:border-amber-500"
      >
        {props.searchOptions.map((o) => {
          return (
            <option key={o.value} value={o.value}>
              {o.name}
            </option>
          );
        })}
      </select>
      <button
        type="submit"
        disabled={props.disabled}
        className="col-span-1 sm:col-span-1 flex sm:inline-flex flex-row items-center bg-stone-600 hover:bg-stone-700 disabled:opacity-60 disabled:hover:bg-stone-600 text-white text-sm font-medium rounded-lg px-4 py-2"
      >
        <SearchIcon className="h-5 w-5 mr-1.5" />
        Search
      </button>
    </form>
  );
});

export default SearchFormV3;
