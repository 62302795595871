import { addDays, subMonths } from "date-fns";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { loadPersistentState, savePersistentState } from "../../utils/helpers/misc";

interface DateRangePickerProps {
  disabled?: boolean;
  updateDates: (newStartDate: string, newEndDate: string) => void;
  defaultStartDate?: string;
  defaultEndDate?: string;
  containerKey?: string;
  onLoadedStateChange?: (loaded: boolean) => void;
}

export type DateRangePickerRef = {
  resetDate: () => void;
};

const DateRangePicker = forwardRef<DateRangePickerRef, DateRangePickerProps>((props, ref) => {
  const [loadedState, setLoadedState] = useState<boolean>(false);

  const [shouldSubmit, setShouldSubmit] = useState<boolean>(true);

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  useImperativeHandle(ref, () => ({
    resetDate: () => {
      const defaultStartDate =
        props.defaultStartDate ?? subMonths(new Date(), 1).toISOString().slice(0, -8);
      const defaultEndDate =
        props.defaultEndDate ?? addDays(new Date(), 1).toISOString().slice(0, -8);
      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
      props.updateDates(defaultStartDate, defaultEndDate);
    },
  }));

  useEffect(() => {
    if (!loadedState) {
      loadState();
      setLoadedState(true);
      props.onLoadedStateChange?.call(null, true);
    }
  });

  useEffect(() => {
    if (loadedState) persistState();
  }, [startDate, endDate, loadedState]);

  const loadState = () => {
    const [startDate, endDate] = loadPersistentState(
      props.containerKey || "",
      ["start-date", "end-date"],
      [
        props.defaultStartDate ?? subMonths(new Date(), 1).toISOString().slice(0, -8),
        props.defaultEndDate ?? addDays(new Date(), 1).toISOString().slice(0, -8),
      ]
    );
    setStartDate(startDate);
    setEndDate(endDate);
    props.updateDates(startDate, endDate);
  };

  const persistState = () => {
    savePersistentState(props.containerKey || "", ["start-date", "end-date"], [startDate, endDate]);
  };

  useEffect(() => {
    if (!loadedState || !shouldSubmit) return;
    props.updateDates(startDate, endDate);
    setShouldSubmit(false);
  }, [startDate, endDate, shouldSubmit, loadedState]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.updateDates(startDate, endDate);
      }}
      className="grid items-center justify-between max-w-2xl grid-cols-1 mb-2 overflow-hidden rounded-lg gap-y-1 gap-x-1 sm:grid-cols-6"
    >
      <div className="col-span-2">
        <label htmlFor="start-date" className="block text-sm font-medium text-left text-gray-700">
          Start Date
        </label>
        <div className="mt-1">
          <input
            type="datetime-local"
            name="start-date"
            id="start-date"
            autoComplete="start-date"
            disabled={props.disabled}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            onBlur={() => setShouldSubmit(true)}
            className="block w-full text-gray-600 border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
          />
        </div>
      </div>

      <div className="col-span-2">
        <label htmlFor="end-date" className="block text-sm font-medium text-left text-gray-700">
          End Date
        </label>
        <div className="mt-1">
          <input
            type="datetime-local"
            name="end-date"
            id="end-date"
            autoComplete="end-date"
            disabled={props.disabled}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            onBlur={() => setShouldSubmit(true)}
            className="block w-full text-gray-600 border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
          />
        </div>
      </div>
    </form>
  );
});

export default DateRangePicker;
