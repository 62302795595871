import { useState } from "react";
import { loadPersistentState, savePersistentState } from "../utils/helpers/misc";

type PersistentState = {
  pageNumber: number;
  rowsPerPage: number;
  sortOrder: "ASC" | "DESC" | "";
  sortField: string;
  loadState: () => void;
  persistState: () => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setPageNumber: (pageNumber: number) => void;
  setSortField: (sortField: string) => void;
  setSortOrder: (sortOrder: "ASC" | "DESC" | "") => void;
  setPageState: (
    pageNumber: number,
    rowsPerPage: number,
    sortOrder: "ASC" | "DESC" | "",
    sortField: string
  ) => void;
};

export function usePersistentState(
  statePrefix: string,
  defaultSortField: string,
  defaultSortOrder: "ASC" | "DESC" | "" = "DESC",
  defaultPageNumber: number = 1,
  defaultRowsPerPage: number = 25
): PersistentState {
  const [pageNumber, setPageNumber] = useState<number>(defaultPageNumber);
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">(defaultSortOrder);
  const [sortField, setSortField] = useState<string>(defaultSortField);

  const loadState = () => {
    const [pageNumber, rowsPerPage, sortOrder, sortField] = loadPersistentState(
      statePrefix,
      ["page-number", "rows-per-page", "sort-order", "sort-field"],
      [defaultPageNumber, defaultRowsPerPage, defaultSortOrder, defaultSortField]
    );
    setPageState(pageNumber, rowsPerPage, sortOrder, sortField);
  };

  const persistState = () => {
    savePersistentState(
      statePrefix,
      ["page-number", "rows-per-page", "sort-order", "sort-field"],
      [pageNumber, rowsPerPage, sortOrder, sortField]
    );
  };

  const setPageState = (
    pageNumber: number,
    rowsPerPage: number,
    sortOrder: string,
    sortField: string
  ) => {
    setPageNumber(pageNumber);
    setRowsPerPage(rowsPerPage);
    setSortOrder(sortOrder as "ASC" | "DESC" | "");
    setSortField(sortField);
  };

  return {
    rowsPerPage,
    pageNumber,
    sortOrder,
    sortField,
    setRowsPerPage,
    setPageNumber,
    setSortField,
    setSortOrder,
    loadState,
    persistState,
    setPageState,
  };
}
