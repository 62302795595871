import {
  DocumentRemoveIcon,
  RefreshIcon,
  ShieldCheckIcon,
  TableIcon,
} from "@heroicons/react/outline";
import { format } from "date-fns";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DateRangePicker, {
  DateRangePickerRef,
} from "../../../components/DateRangePicker/DateRangePicker";
import ExportPersonAuditCSVModal from "../../../components/ExportPersonAuditCSVModal/ExportPersonAuditCSVModal";
import PageNumbers from "../../../components/PageNumbers/PageNumbers";
import SearchFormV3, { SearchFormV3Ref } from "../../../components/SearchFormV3/SearchForm";
import Sidebar from "../../../components/Sidebar/Sidebar";
import SortButton from "../../../components/SortButton/SortButton";
import { useAuth } from "../../../utils/auth/auth";
import { apiV3 } from "../../../utils/helpers/api";
import { getSubmittedOnFormattedDate } from "../../../utils/helpers/misc";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { usePageInitialization } from "../../../hooks/usePageInitialization";
import { usePersistentState } from "../../../hooks/usePersistentState";

export default function PersonSecuritySubmissionsV3() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [exportCsvModalOpen, setExportCsvModalOpen] = useState<boolean>(false);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState<any>(false);
  const [selectedPersonSecuritySubmissions, setSelectedPersonSecuritySubmissions] = useState<any[]>(
    []
  );

  const [personSecuritySubmissions, setPersonSecuritySubmissions] = useState<any[]>([]);
  const [newPageNumber, setNewPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useAuthToken();
  const { isInitiated } = usePageInitialization();

  const [stateLoaded, setStateLoaded] = useState<boolean>(false);
  const [searchFormLoadedState, setSearchFormLoadedState] = useState<boolean>(false);
  const [dateLoadedState, setDateLoadedState] = useState<boolean>(false);
  const datePickerRef = useRef<DateRangePickerRef>(null);
  const searchFormRef = useRef<SearchFormV3Ref>(null);

  const statePrefix = "person-security-submissions-";
  const {
    rowsPerPage,
    setRowsPerPage,
    pageNumber,
    setPageNumber,
    sortOrder,
    setSortOrder,
    sortField,
    setSortField,
    persistState,
    loadState,
    setPageState,
  } = usePersistentState(statePrefix, "submittedOn");

  useEffect(() => {
    if (isInitiated && !stateLoaded) {
      loadState();
      setStateLoaded(true);
    }
  }, [isInitiated, stateLoaded]);

  useEffect(() => {
    if (isInitiated && stateLoaded && searchFormLoadedState && dateLoadedState) {
      persistState();
      fetchPersonSecuritySubmissions();
    }
  }, [
    pageNumber,
    rowsPerPage,
    searchQuery,
    searchField,
    sortOrder,
    sortField,
    role,
    startDate,
    endDate,
    isInitiated,
    stateLoaded,
    searchFormLoadedState,
    dateLoadedState,
  ]);

  const resetState = () => {
    datePickerRef.current?.resetDate();
    searchFormRef.current?.resetSearchForm();
    setPageState(1, 25, "DESC", "submittedOn");
  };

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPersonSecuritySubmissions.length > 0 &&
      selectedPersonSecuritySubmissions.length < personSecuritySubmissions.length;
    setChecked(
      selectedPersonSecuritySubmissions.length !== 0 &&
        selectedPersonSecuritySubmissions.length === personSecuritySubmissions.length
    );
    setIndeterminate(isIndeterminate);
    (checkbox.current! as any).indeterminate = isIndeterminate;
  }, [selectedPersonSecuritySubmissions, loading]);

  function toggleAll() {
    setSelectedPersonSecuritySubmissions(checked || indeterminate ? [] : personSecuritySubmissions);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const fetchPersonSecuritySubmissions = async () => {
    setLoading(true);
    try {
      const personSecuritySubmissions = await apiV3.getPersonSecuritySubmissions(
        pageNumber,
        rowsPerPage,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        startDate,
        endDate,
        timezone
      );
      if (personSecuritySubmissions.status === 200 && personSecuritySubmissions.data.data) {
        setTotalCount(personSecuritySubmissions.data.totalCount);
        setPersonSecuritySubmissions(personSecuritySubmissions.data.data);
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} personSecuritySubmissions of page ${pageNumber} - `,
        error
      );
    }
    setLoading(false);
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === "DESC") setSortOrder("ASC");
      else setSortField("");
    } else {
      setSortOrder("DESC");
    }
  };

  const updateRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    //After resetting the rows per page, we need to set the current page to 1.
    //Otherwise, if the current page is larger than the total available page, there will be no records loaded.
    setPageNumber(1);
  };

  return (
    <>
      <Sidebar />
      <div className="flex flex-col flex-1 md:pl-64">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="flex flex-row items-center text-2xl font-bold text-gray-900">
                    <ShieldCheckIcon className="h-6 w-6 mr-1.5" />
                    Person Audits
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the person audits in AEV V3 including their details.
                  </p>
                </div>
                <div className="flex flex-row items-center mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  {((auth.user.hasOwnProperty("role") &&
                    (auth.user.role === 1 || auth.user.role === 2)) ||
                    (auth.user.hasOwnProperty("roleName") &&
                      auth.user.roleName === "Airport Administrator")) && (
                    <button
                      onClick={() => setExportCsvModalOpen(true)}
                      // onClick={() => apiV3.exportPersonSecuritySubmissions()}
                      type="button"
                      disabled={loading}
                      className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-blue-500"
                    >
                      <TableIcon className="h-5 w-5 mr-1.5" />
                      Export
                    </button>
                  )}
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchPersonSecuritySubmissions()}
                    className="inline-flex items-center justify-center px-4 py-2 ml-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-stone-600 hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:hover:bg-stone-600"
                  >
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => resetState()}
                    className="inline-flex items-center justify-center px-4 py-2 ml-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:w-auto disabled:hover:bg-yellow-600"
                  >
                    <DocumentRemoveIcon className="h-5 w-5 mr-1.5" />
                    Reset
                  </button>
                </div>
              </div>
              <div className="flex flex-col mt-4">
                <DateRangePicker
                  containerKey={statePrefix}
                  updateDates={(newStartDate, newEndDate) => {
                    setStartDate(newStartDate);
                    setEndDate(newEndDate);
                  }}
                  disabled={loading}
                  onLoadedStateChange={(loaded) => setDateLoadedState(loaded)}
                  ref={datePickerRef}
                />
                <SearchFormV3
                  containerKey={statePrefix}
                  searchOptions={[
                    { name: "ASIC ID", value: "asicId" },
                    { name: "First Name", value: "firstName" },
                    { name: "Last Name", value: "lastName" },
                    { name: "Company", value: "company" },
                    { name: "Location Name", value: "locationName" },
                  ]}
                  updateSearchQueryAndField={(newSearchQuery, newSearchField) => {
                    setSearchQuery(newSearchQuery);
                    setSearchField(newSearchField);
                  }}
                  disabled={loading}
                  onLoadedStateChange={(loaded) => setSearchFormLoadedState(loaded)}
                  ref={searchFormRef}
                />

                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                              <input
                                type="checkbox"
                                className="absolute w-4 h-4 -mt-2 border-gray-300 rounded left-4 top-1/2 text-amber-600 focus:ring-amber-600"
                                ref={checkbox as any}
                                checked={checked}
                                onChange={toggleAll}
                              />
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Submitted On
                                <SortButton
                                  active={sortField === "submittedOn"}
                                  sortOrder={sortOrder}
                                  sortField={"submittedOn"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                ASIC ID
                                <SortButton
                                  active={sortField === "asicId"}
                                  sortOrder={sortOrder}
                                  sortField={"asicId"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                First Name
                                <SortButton
                                  active={sortField === "firstName"}
                                  sortOrder={sortOrder}
                                  sortField={"firstName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Last Name
                                <SortButton
                                  active={sortField === "lastName"}
                                  sortOrder={sortOrder}
                                  sortField={"lastName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Company
                                <SortButton
                                  active={sortField === "company"}
                                  sortOrder={sortOrder}
                                  sortField={"company"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Location Name
                                <SortButton
                                  active={sortField === "locationName"}
                                  sortOrder={sortOrder}
                                  sortField={"locationName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Status
                                <SortButton
                                  active={sortField === "pass"}
                                  sortOrder={sortOrder}
                                  sortField={"pass"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">View</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {personSecuritySubmissions.map((personSecuritySubmission) => {
                            return (
                              <tr
                                key={personSecuritySubmission.id}
                                className="cursor-pointer hover:bg-slate-50"
                                onClick={() =>
                                  navigate("/v3/person-audits/edit/" + personSecuritySubmission.id)
                                }
                              >
                                <td className="relative px-7 sm:w-12 sm:px-6">
                                  {selectedPersonSecuritySubmissions.includes(
                                    personSecuritySubmission
                                  ) && (
                                    <div className="absolute inset-y-0 left-0 w-0.5 bg-amber-600" />
                                  )}
                                  <input
                                    type="checkbox"
                                    className="absolute w-4 h-4 -mt-2 border-gray-300 rounded left-4 top-1/2 text-amber-600 focus:ring-amber-600"
                                    value={personSecuritySubmission.asicId}
                                    checked={selectedPersonSecuritySubmissions.includes(
                                      personSecuritySubmission
                                    )}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      setSelectedPersonSecuritySubmissions(
                                        e.target.checked
                                          ? [
                                              ...selectedPersonSecuritySubmissions,
                                              personSecuritySubmission,
                                            ]
                                          : selectedPersonSecuritySubmissions.filter(
                                              (p) => p !== personSecuritySubmission
                                            )
                                      );
                                    }}
                                  />
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                  {personSecuritySubmission.submittedOnLocal
                                    ? getSubmittedOnFormattedDate(
                                        personSecuritySubmission.submittedOnLocal
                                      )
                                    : format(
                                        new Date(personSecuritySubmission.submittedOn),
                                        "dd/MM/yyyy hh:mm aa"
                                      )}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                  {personSecuritySubmission.asicId ?? ""}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                  {personSecuritySubmission.firstName ?? ""}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                  {personSecuritySubmission.lastName ?? ""}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                  {personSecuritySubmission.company ?? ""}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                  {personSecuritySubmission.locationName ?? "N/A"}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                  {personSecuritySubmission.abandoned ? (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                      Abandoned
                                    </div>
                                  ) : personSecuritySubmission.pass ? (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                      Passed
                                    </div>
                                  ) : (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                      Failed
                                    </div>
                                  )}
                                </td>
                                <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                                  <Link
                                    to={"/v3/person-audits/edit/" + personSecuritySubmission.id}
                                  >
                                    <div className="text-amber-600 hover:text-amber-900">View</div>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex items-center justify-between py-3 border-t border-gray-200">
                      <div className="flex justify-between flex-1 sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                        >
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex flex-row items-center">
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <>
                              <p className="text-sm text-gray-700">
                                Showing{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber - rowsPerPage + 1}
                                </span>{" "}
                                to{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber > totalCount
                                    ? totalCount
                                    : rowsPerPage * pageNumber}
                                </span>{" "}
                                of <span className="font-medium">{totalCount}</span> results
                              </p>
                            </>
                          ) : (
                            <p className="text-sm text-gray-700">Showing 0 results.</p>
                          )}
                          <div className="text-sm text-gray-700 inline-flex flex-row items-center ml-1.5">
                            <div>Results per page: </div>
                            <div>
                              <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                className="h-8 ml-1.5 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-600 text-sm sm:leading-6"
                                value={rowsPerPage}
                                onChange={(e) => updateRowsPerPage(parseInt(e.target.value))}
                                disabled={loading}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <PageNumbers
                          rowsPerPage={rowsPerPage}
                          totalCount={totalCount}
                          pageNumber={pageNumber}
                          setPageNumber={setPageNumber}
                          newPageNumber={newPageNumber}
                          setNewPageNumber={setNewPageNumber}
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ExportPersonAuditCSVModal
        open={exportCsvModalOpen}
        setOpen={setExportCsvModalOpen}
        selectedPersonAudits={selectedPersonSecuritySubmissions}
      />
    </>
  );
}
