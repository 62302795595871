import {
  DocumentRemoveIcon,
  DocumentTextIcon,
  PlusIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageNumbers from "../../../components/PageNumbers/PageNumbers";
import SearchForm, { SearchFormV3Ref } from "../../../components/SearchFormV3/SearchForm";
import Sidebar from "../../../components/Sidebar/Sidebar";
import SortButton from "../../../components/SortButton/SortButton";
import { apiV3 } from "../../../utils/helpers/api";
import { useToast } from "../../../components/Toastr/Toastr";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { usePageInitialization } from "../../../hooks/usePageInitialization";
import { usePersistentState } from "../../../hooks/usePersistentState";

export default function OperationalNeedsV3() {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const [operationalNeeds, setOperationalNeeds] = useState<any[]>([]);
  const [newPageNumber, setNewPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");

  useAuthToken();
  const { isInitiated } = usePageInitialization();

  const [stateLoaded, setStateLoaded] = useState<boolean>(false);
  const [searchFormLoadedState, setSearchFormLoadedState] = useState<boolean>(false);
  const searchFormRef = useRef<SearchFormV3Ref>(null);

  const statePrefix = "operational-needs-";
  const {
    rowsPerPage,
    setRowsPerPage,
    pageNumber,
    setPageNumber,
    sortOrder,
    setSortOrder,
    sortField,
    setSortField,
    persistState,
    loadState,
    setPageState,
  } = usePersistentState(statePrefix, "createdAt");

  useEffect(() => {
    if (isInitiated && !stateLoaded) {
      loadState();
      setStateLoaded(true);
    }
  }, [isInitiated, stateLoaded]);

  const resetState = () => {
    searchFormRef.current?.resetSearchForm();
    setPageState(1, 25, "DESC", "createdAt");
  };

  useEffect(() => {
    if (isInitiated && stateLoaded && searchFormLoadedState) {
      persistState();
      fetchOperationalNeeds();
    }
  }, [
    pageNumber,
    rowsPerPage,
    searchQuery,
    searchField,
    sortOrder,
    sortField,
    isInitiated,
    stateLoaded,
    searchFormLoadedState,
  ]);

  const fetchOperationalNeeds = async () => {
    setLoading(true);
    try {
      const opNeeds = await apiV3.getOperationalNeeds(
        pageNumber,
        rowsPerPage,
        searchQuery,
        searchField,
        sortOrder,
        sortField
      );
      setTotalCount(opNeeds.data.totalCount);
      setOperationalNeeds(opNeeds.data.data);
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} operational needs of page ${pageNumber} - `,
        error
      );
      showErrorToast();
    }
    setLoading(false);
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === "DESC") setSortOrder("ASC");
      else setSortField("");
    } else {
      setSortOrder("DESC");
    }
  };

  const updateRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <DocumentTextIcon className="h-6 w-6 mr-1.5" />
                    Operational Needs
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the operational needs in AEV including their details.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none items-center flex flex-row">
                  <button
                    onClick={() => navigate("/v3/operational-needs/add")}
                    type="button"
                    disabled={loading}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-amber-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-amber-500"
                  >
                    <PlusIcon className="h-5 w-5 mr-1.5" />
                    Add Operational Need
                  </button>
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchOperationalNeeds()}
                    className="sm:ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:hover:bg-stone-600"
                  >
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => resetState()}
                    className="inline-flex items-center justify-center px-4 py-2 ml-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:w-auto disabled:hover:bg-yellow-600"
                  >
                    <DocumentRemoveIcon className="h-5 w-5 mr-1.5" />
                    Reset
                  </button>
                </div>
              </div>
              <div className="mt-4 sm:mt-8 flex flex-col">
                <SearchForm
                  containerKey={statePrefix}
                  searchOptions={[{ name: "Name", value: "name" }]}
                  updateSearchQueryAndField={(newSearchQuery, newSearchField) => {
                    setSearchQuery(newSearchQuery);
                    setSearchField(newSearchField);
                  }}
                  onLoadedStateChange={(loaded) => setSearchFormLoadedState(loaded)}
                  ref={searchFormRef}
                />
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Created At
                                <SortButton
                                  active={sortField === "createdAt"}
                                  sortOrder={sortOrder}
                                  sortField={"createdAt"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Name
                                <SortButton
                                  active={sortField === "name"}
                                  sortOrder={sortOrder}
                                  sortField={"name"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6 cursor-pointer"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {operationalNeeds.map((val) => (
                            <tr key={val.id} className="">
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {new Date(val.createdAt).toDateString()}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {val.name}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <div
                                  onClick={() => navigate("/v3/operational-needs/edit/" + val.id)}
                                  className="text-amber-600 hover:text-amber-900 cursor-pointer"
                                >
                                  Edit
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="py-3 flex items-center justify-between border-t border-gray-200">
                      <div className="flex-1 flex justify-between sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex flex-row items-center">
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <>
                              <p className="text-sm text-gray-700">
                                Showing{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber - rowsPerPage + 1}
                                </span>{" "}
                                to{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber > totalCount
                                    ? totalCount
                                    : rowsPerPage * pageNumber}
                                </span>{" "}
                                of <span className="font-medium">{totalCount}</span> results
                              </p>
                            </>
                          ) : (
                            <p className="text-sm text-gray-700">Showing 0 results.</p>
                          )}
                          <div className="text-sm text-gray-700 inline-flex flex-row items-center ml-1.5">
                            <div>Results per page: </div>
                            <div>
                              <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                className="h-8 ml-1.5 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-600 text-sm sm:leading-6"
                                value={rowsPerPage}
                                onChange={(e) => updateRowsPerPage(parseInt(e.target.value))}
                                disabled={loading}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <PageNumbers
                          rowsPerPage={rowsPerPage}
                          totalCount={totalCount}
                          pageNumber={pageNumber}
                          setPageNumber={setPageNumber}
                          newPageNumber={newPageNumber}
                          setNewPageNumber={setNewPageNumber}
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
