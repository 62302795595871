import { addHours, addSeconds } from "date-fns";
import React, { useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { apiV3 } from "../helpers/api";

interface ErrorObject {
  code: string;
  message: string;
}
interface AuthContextType {
  user: any;
  v3Token: string;
  v3TokenExpiry: Date | null;
  loading: boolean;
  signin: (username: string, password: string) => Promise<ErrorObject | void>;
  signout: (callback?: VoidFunction) => void;
  updateV3Token: (newToken: string) => void;
  updateUser: (newUser?: any) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();

  const [v3Token, setV3Token] = React.useState<string>(localStorage.getItem("v3Token") ?? "");
  const [v3TokenExpiry, setV3TokenExpiry] = React.useState<Date | null>(
    localStorage.getItem("v3TokenExpiry") ? new Date(localStorage.getItem("v3TokenExpiry")!) : null
  );
  const [user, setUser] = React.useState<any>(
    JSON.parse(localStorage.getItem("currentUser") || "null")
  );
  const [loading, setLoading] = useState<boolean>(false);

  const updateV3Token = (newToken: string) => {
    setV3Token(newToken);
    localStorage.setItem("v3Token", newToken);
  };

  const updateV3TokenExpiry = (newTokenExpiry: Date) => {
    setV3TokenExpiry(newTokenExpiry);
    localStorage.setItem("v3TokenExpiry", newTokenExpiry.toISOString());
  };

  const updateUser = (newUser?: any) => {
    setLoading(true);
    if (newUser) {
      setUser(newUser);
      localStorage.setItem("currentUser", JSON.stringify(newUser));
    }
    setLoading(false);
  };

  const signin = async (username: string, password: string): Promise<void | ErrorObject> => {
    setLoading(true);
    try {
      await signInToV3(username, password);
    } catch (error) {
      // Try logging in to V3 API
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signInToV3 = async (username: string, password: string): Promise<void | ErrorObject> => {
    try {
      // Try logging in to V3 API
      const res = await apiV3.loginUser(username, password);
      if (res.data.token && res.data.user) {
        // Update v3 tokens
        const newTokenExpiry = addHours(new Date(), 24);
        updateV3Token(res.data.token);
        updateV3TokenExpiry(newTokenExpiry);
        apiV3.updateClient(res.data.token, newTokenExpiry);

        // Update user
        updateUser(res.data.user);
      } else {
        throw new Error("Invalid login user response from V3 API");
      }
    } catch (error) {
      console.error("Error logging in to V3 API: ", error);
      throw error;
    }
  };

  const signout = (callback?: VoidFunction) => {
    setUser(null);

    // Remove V3 Tokens
    setV3Token("");
    setV3TokenExpiry(null);
    localStorage.removeItem("v3Token");
    localStorage.removeItem("v3TokenExpiry");

    localStorage.removeItem("currentUser");

    if (callback) {
      callback();
    }
  };

  const memoedValue = useMemo(
    () => ({
      user,
      v3Token,
      v3TokenExpiry,
      loading,
      signin,
      signout,
      updateV3Token,
      updateUser,
    }),
    [v3Token, loading]
  );

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const auth = useAuth();

  if (!auth.v3Token) {
    return <Navigate to="/signin" replace state={{ from: location.pathname }} />;
  }

  return children;
}
