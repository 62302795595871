import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentRemoveIcon,
  PlusIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { SwitchHorizontalIcon } from "@heroicons/react/solid";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import SortButton from "../../../components/SortButton/SortButton";
import { useAuth } from "../../../utils/auth/auth";
import { apiV3 } from "../../../utils/helpers/api";
import SearchFormV3, { SearchFormV3Ref } from "../../../components/SearchFormV3/SearchForm";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { usePageInitialization } from "../../../hooks/usePageInitialization";
import { usePersistentState } from "../../../hooks/usePersistentState";

export default function NewASICImportV3() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [importedAsics, setImportedAsics] = useState<any[]>([]);
  const [newPageNumber, setNewPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [importButtonDisabled, setImportButtonDisabled] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");

  useAuthToken();
  const { isInitiated } = usePageInitialization();

  const [stateLoaded, setStateLoaded] = useState<boolean>(false);
  const [searchFormLoadedState, setSearchFormLoadedState] = useState<boolean>(false);
  const searchFormRef = useRef<SearchFormV3Ref>(null);

  const statePrefix = "new-asic-imports-";
  const {
    rowsPerPage,
    setRowsPerPage,
    pageNumber,
    setPageNumber,
    sortOrder,
    setSortOrder,
    sortField,
    setSortField,
    persistState,
    loadState,
    setPageState,
  } = usePersistentState(statePrefix, "importedOn");

  useEffect(() => {
    if (isInitiated && !stateLoaded) {
      loadState();
      setStateLoaded(true);
    }
  }, [isInitiated, stateLoaded]);

  useEffect(() => {
    if (isInitiated && stateLoaded && searchFormLoadedState) {
      persistState();
      fetchImportedAsics();
    }
  }, [
    pageNumber,
    rowsPerPage,
    searchQuery,
    searchField,
    sortField,
    sortOrder,
    isInitiated,
    stateLoaded,
    searchFormLoadedState,
  ]);

  const resetState = () => {
    searchFormRef.current?.resetSearchForm();
    setPageState(1, 25, "DESC", "importedOn");
  };

  const fetchImportedAsics = async () => {
    setLoading(true);
    try {
      const asicImports = await apiV3.getNewASICImports(
        pageNumber,
        rowsPerPage,
        searchQuery,
        searchField,
        sortOrder,
        sortField
      );

      setImportedAsics(asicImports.data.data);
      setTotalCount(asicImports.data.totalCount);
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} asic imports of page ${pageNumber} - `,
        error
      );
    }
    setLoading(false);
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === "DESC") setSortOrder("ASC");
      else setSortField("");
    } else {
      setSortOrder("DESC");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <SwitchHorizontalIcon className="h-6 w-6 mr-1.5" />
                    ASIC Import
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the ASIC imports in AEV including their details.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none items-center flex flex-row">
                  <button
                    onClick={() => navigate("/v3/new-asic-import/import")}
                    type="button"
                    disabled={loading || importButtonDisabled}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-amber-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-amber-500"
                  >
                    <PlusIcon className="h-5 w-5 mr-1.5" />
                    Import ASIC
                  </button>
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchImportedAsics()}
                    className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-stone-600"
                  >
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>

                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => resetState()}
                    className="inline-flex items-center justify-center px-4 py-2 ml-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:w-auto disabled:hover:bg-yellow-600"
                  >
                    <DocumentRemoveIcon className="h-5 w-5 mr-1.5" />
                    Reset
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col">
                <SearchFormV3
                  containerKey={statePrefix}
                  searchOptions={[{ name: "Filename", value: "originalFileName" }]}
                  updateSearchQueryAndField={(newSearchQuery, newSearchField) => {
                    setSearchQuery(newSearchQuery);
                    setSearchField(newSearchField);
                  }}
                  onLoadedStateChange={(loaded) => setSearchFormLoadedState(loaded)}
                  ref={searchFormRef}
                />
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Imported On
                                <SortButton
                                  active={sortField === "importedOn"}
                                  sortOrder={sortOrder}
                                  sortField={"importedOn"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Filename
                                <SortButton
                                  active={sortField === "originalFileName"}
                                  sortOrder={sortOrder}
                                  sortField={"originalFileName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                New
                                <SortButton
                                  active={sortField === "newAsicsAdded"}
                                  sortOrder={sortOrder}
                                  sortField={"newAsicsAdded"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Updated
                                <SortButton
                                  active={sortField === "asicsUpdated"}
                                  sortOrder={sortOrder}
                                  sortField={"asicsUpdated"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Deactivated
                                <SortButton
                                  active={sortField === "asicsDeactivated"}
                                  sortOrder={sortOrder}
                                  sortField={"asicsDeactivated"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Start Time
                                <SortButton
                                  active={sortField === "processingStartTime"}
                                  sortOrder={sortOrder}
                                  sortField={"processingStartTime"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                End Time
                                <SortButton
                                  active={sortField === "processingEndTime"}
                                  sortOrder={sortOrder}
                                  sortField={"processingEndTime"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Status
                                <SortButton
                                  active={sortField === "status"}
                                  sortOrder={sortOrder}
                                  sortField={"status"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {importedAsics.map((asic) => (
                            <tr
                              key={asic.id}
                              className="hover:bg-slate-50 cursor-pointer"
                              onClick={() => navigate("/v3/new-asic-import/edit/" + asic.id)}
                            >
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {new Date(asic.importedOn).toDateString()}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.originalFileName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.newAsicsAdded ?? "N/A"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.asicsUpdated ?? "N/A"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.asicsDeactivated ?? "N/A"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {new Date(asic.processingStartTime).toLocaleTimeString()}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.processingEndTime
                                  ? new Date(asic.processingEndTime).toLocaleTimeString()
                                  : "Unfinished"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {asic.status === 1 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                    Pending
                                  </div>
                                )}
                                {asic.status === 2 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                    Processing
                                  </div>
                                )}
                                {asic.status === 3 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Imported
                                  </div>
                                )}
                                {asic.status === 4 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Failed
                                  </div>
                                )}
                                {asic.status === 0 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-stone-100 text-stone-800">
                                    Unknown
                                  </div>
                                )}
                              </td>

                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link to={"/asic-import/edit/" + asic.id}>
                                  <div className="text-amber-600 hover:text-amber-900">View</div>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="py-3 flex items-center justify-between">
                      <div className="flex-1 flex justify-between sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex flex-row items-center">
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <>
                              {/* <p className="text-sm text-gray-700">
                                Showing{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber - rowsPerPage + 1}
                                </span>{" "}
                                to{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber > totalCount
                                    ? totalCount
                                    : rowsPerPage * pageNumber}
                                </span>{" "}
                                of{" "}
                                <span className="font-medium">
                                  {totalCount}
                                </span>{" "}
                                results
                              </p> */}
                              <p className="text-sm text-gray-700">Showing {totalCount} results.</p>
                            </>
                          ) : (
                            <p className="text-sm text-gray-700">Showing 0 results.</p>
                          )}
                          {/* <div className="text-sm text-gray-700 inline-flex flex-row items-center ml-1.5">
                            <div>Results per page: </div>
                            <div>
                              <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                className="h-8 ml-1.5 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-600 text-sm sm:leading-6"
                                value={rowsPerPage}
                                onChange={(e) =>
                                  setRowsPerPage(parseInt(e.target.value))
                                }
                                disabled={loading}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div> */}
                        </div>
                        <div>
                          <nav
                            className="bg-white relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                            aria-label="Pagination"
                          >
                            <button
                              onClick={() => setPageNumber(pageNumber - 1)}
                              disabled={pageNumber === 1 || loading}
                              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white"
                            >
                              <span className="sr-only">Previous</span>
                              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            {/* {createPageNumbers()} */}
                            <button
                              onClick={() => setPageNumber(pageNumber + 1)}
                              disabled={totalCount < rowsPerPage || loading}
                              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white"
                            >
                              <span className="sr-only">Next</span>
                              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
